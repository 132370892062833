<template>
  <footer class="relative bg-blueGray-200 pt-12 pb-6">
      <div
       class="flex flex-wrap items-center justify-center"
      >
      
        
  <a href="https://twitter.com/Qshare_co_id">
        <img class="px-1"
        :src="iconTwitter"
        width="30"
        alt="..."
        /></a>

          

       <a href="https://www.instagram.com/qshare.co.id/">
        <img class="px-1"
        :src="iconIg"
        width="30"
        alt="..."
        />
</a>
         
<a href="https://id.linkedin.com/company/qshareindonesia">
        <img class="px-1"
        :src="iconLinkedin"
        width="30"
        alt="..."
        />
        </a>

        <a href="https://vt.tiktok.com/ZSdrLBR5L">
        <img class="px-1"
        :src="iconTiktok"
        width="30"
        alt="..."
        />
        </a>

        <a href="https://discord.gg/U7YNDyD3ZD">
        <img class="px-1"
        :src="iconDiscord"
        width="30"
        alt="..."
        />
        </a>

       

        


         
        

      </div>


      <div
        class="flex flex-wrap items-center md:justify-between justify-center"
      >
        <div class="w-full md:w-4/12 px-4 mx-auto text-center pt-5">
          <div class="text-sm text-blueGray-500 font-semibold py-1 text-[#828282]">
            © Copyright Qshare | All Rights Reserved
            <a
              href="https://www.creative-tim.com?ref=vn-footer"
              class="text-blueGray-500 hover:text-blueGray-800 text-[#828282]"
            >
             
            </a>
            .
          </div>
        </div>
      </div>
  </footer>
</template>
<script>
import iconIg from "@/assets/img/ig.png";
import iconFb from "@/assets/img/fb.png";
import iconTwitter from "@/assets/img/twitter.png";
import iconGithub from "@/assets/img/Githubiconall.png";
import iconLonceng from "@/assets/img/lonceng.png";
import iconLinkedin from "@/assets/img/linkedin.png";
import iconTiktok from "@/assets/img/tiktok.png";
import iconDiscord from "@/assets/img/discord1.png"
export default {
  data() {
    return {
      iconFb,
      iconTwitter,
      iconGithub ,
      iconLonceng,
      iconLinkedin,
      iconIg,
      iconDiscord,
      iconTiktok,
      date: new Date().getFullYear(),
    };
  },
};
</script>
