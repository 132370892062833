<script setup>
defineProps({
    canLogin: Boolean,
    canRegister: Boolean,
    laravelVersion: String,
    phpVersion: String,
})

</script>
<script>
import FooterComponent from "@/components/Footers/Footer.vue";
import { Head, Link } from '@inertiajs/inertia-vue3';
import patternVue from "@/assets/img/qshare.png";
import componentBtn from "@/assets/img/component-btn.png";
import componentProfileCard from "@/assets/img/component-profile-card.png";
import componentInfoCard from "@/assets/img/component-info-card.png";
import componentInfo2 from "@/assets/img/component-info-2.png";
import componentMenu from "@/assets/img/component-menu.png";
import componentBtnPink from "@/assets/img/component-btn-pink.png";
import documentation from "@/assets/img/documentation.png";
import login from "@/assets/img/login.jpg";
import profile from "@/assets/img/profile.jpg";
import landing from "@/assets/img/landing.jpg";
import SplashScreen from "@/components/SplashScreen";
import axios from 'axios';
export default {
  props : {
    canLogin: Boolean,
    canRegister: Boolean,
    laravelVersion: String,
    phpVersion: String,
  },
  data() {
    return {
      patternVue,
      SplashScreen,
      Head,
      Link,
      componentBtn,
      componentProfileCard,
      componentInfoCard,
      componentInfo2,
      componentMenu,
      componentBtnPink,
      documentation,
      login,
      profile,
      landing,
      message: '',
      product: {},
      product: {
        name: "1"
      },
      isLoading: true,
      timerCount: 30,
 
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  },
  async created(){
   
    await this.minute();
    await this.hour();
    
  },
  methods: {
    hapusinfo(){
      this.message = '';
    },
    
    day: function () {
      let start = new Date();
      let end   = new Date('2022-06-07');

      let diff = Math.abs(end - start);
      let day = Math.floor(diff / (1000 * 60 * 60 * 24));

      return day;

    },
    handleClick: function(){
      document.getElementById("alertss").style.display = "none"
    },
    addProduct() {
                axios
                    .post(this.$apiAdress + '/product', this.product)
                    .then(response => {
                        document.getElementById("alertss").style.display = "block"
                        document.getElementById("email").innerHTML = response.data
                    
                    })
                    
                    .catch(err =>{
                      this.message = 'Invalid email entered';
                    })
                    .finally(() => this.loading = false)
            },
    
    hour: function () {
      let start = new Date();
      let hour = 24 - start.getHours();
      return hour;
    },
    minute: function () {
      let start = new Date();
      let minute = 60 - start.getMinutes();
      return minute;
    },

  },
  watch: {

            timerCount: {
                handler(value) {

                    if (value > 0) {
                        setTimeout(() => {
                            this.timerCount--;
                        }, 1000);
                    }

                },
                immediate: true // This ensures the watcher is triggered upon creation
            }

        },
  components: {
    FooterComponent
  },
};
</script>
<style>
/* Style the search field */
form.example input[type=text] {
  padding: 10px;
  font-size: 10px;
  border: 1px solid grey;
  border-radius: 200px 0px 0px 200px;
  float: left;
  width: 50%;
  background: #ffffff;
}

/* Style the submit button */
form.example button {
  float: left;
  width: 50%;
  padding: 10px;
  background: #D89A47;
  color: white;
  font-size: 16px;
  border: 1px solid grey;
  border-radius: 0px 200px 200px 0px;
  border-left: none; /* Prevent double borders */
  cursor: pointer;
}

@media only screen and (max-width: 640px) {
  .visible {width: 100%;}
}
</style>
<template>
  <SplashScreen v-if="isLoading" />
  <div v-else-if="!isLoading">

  <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-auto bg-white border border-gray-100 " id="alertss" ref="alertss" style="display:none; z-index: 10;position:absolute;">
      <div class="flex justify-center items-center self-center w-3 bg-gradient-to-t from-green-500 to-green-400"></div>
      <div class="flex-1 justify-center items-center p-3 self-center">
        <center><h1 class="md:text-xl text-gray-600">Info</h1></center>
        <p class="text-gray-400 text-xs md:text-sm font-light"><a id="email"></a> Please wait for our next information.</p>
      </div>
      <div class="flex-1 justify-center items-center self-center border-l border-gray-100 px-4 place-items-center">
        <center><button class="text-gray-400 text-xs hover:bg-gray-50" @click="handleClick" style="height:70px; text-align:center;">CLOSE</button></center>
      </div>
  </div>

  <div class="invisible sm:visible" style="z-index:1;" >

    <div v-if="canLogin" class="hidden fixed bottom-0 left-0 px-6 py-4 sm:block">
              <Link v-if="$page.props.auth.user" :href="route('dashboard')" class="text-sm text-gray-700 underline">
                  Dashboard
              </Link>

              <template v-else>
                  <Link :href="route('login')" class="text-sm text-gray-700 underline">
                      Log in
                  </Link>

                  <Link v-if="canRegister" :href="route('register')" class="ml-4 text-sm text-gray-700 underline">
                      Register
                  </Link>
              </template>
    </div>


    <section class="sm">
      <div class="flex header sm relative items-center" style="width: 100%;/* height: 9rem; */">
        <img
          class="flex m-auto top-0 left-0 right-0 pt-16"
          :src="patternVue" style="max-width: 30%; vertical-align:top;"
          alt="..."
        />
      </div>

      <div  class="flex sm" style="">
        <div  class="grow sm sm:w-4/12" style="
                                        position: relative;
                                        width: 30%;
                                        font-size: 1800%;                                    
                                      top:-5rem;
                                        font-family: 'Poppins';
                                        font-style: normal;
                                        font-weight: 700;
                              
                                        /* identical to box height */
                                        letter-spacing: 0.01em;
                                        -webkit-text-stroke: 2px #f7ebda;
                                        color:white;
        ">
          <span style="font-size:20%;"><b style="font-size:500%;">{{ day() }}</b>d</span>
        </div>

        <div class="grow sm sm:w-4/12" style="
                                        position: relative;
                                        width: 30%;
                                        font-size: 1800%;                                                                         
                                        text-align:center;
                                        font-family: 'Poppins';
                                        font-style: normal;
                                        font-weight: 700;
                                        top:-5rem;
                                        /* identical to box height */
                                        letter-spacing: 0.01em;
                                        -webkit-text-stroke: 2px #f7ebda;
                                        color:white;
        ">
          <span style="font-size:20%;"><b style="font-size:500%;">{{ hour() }}</b>h</span>
        </div>

        <div class="grow sm sm:w-4/12" style="
                                      -webkit-text-stroke: 2px #f7ebda;
                                      color:white;
                                      position: relative;
                                      width: 30%;
                                      margin-right: 0;
                                      font-size: 1800%;                                   
                                      text-align:right;
                                      font-family: 'Poppins';
                                      font-style: normal;
                                      font-weight: 700;
                                      top:-5rem;
                                      /* identical to box height */
                                      letter-spacing: 0.01em;

        ">
          <span style="font-size:20%;"><b style="font-size:500%;">{{ minute() }}</b>m</span>
        </div>
      </div>
  </section>

  <section class="sm" style="margin-top:-9%; ">
      <div class="flex sm" 
      style=" padding-top: -4%;padding-left:8%;
    ">
        <div class="relative sm flex-1 h-16 w-full text-5xl" style="font-family:poppins; font-weight:bold; color:#D89A47; ">
        <p>We are</p>                                                                                                                       
        <p style="padding-top:15px">Coming Soon.</p>          
        </div>


        <div class="flex-1 sm relative text-gray-700" style="margin-right:39px; padding-top:10px;">
        <p
        style="
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
                letter-spacing: 0.01em;
                color: #000000;
                " 
        >Get notified when we launch</p>
        <form @submit.prevent="addProduct">
        <p>
        <input 
          style="
                  border: 1px solid #E0E0E0;
                  box-sizing: border-box;
                  border-radius: 400px 0px 0px 400px;"
          
    class="sm w-8/12 h-10 pl-3 pr-8 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline z-0 form-control" 
    type="text" placeholder="Email" v-model="product.detail" @input="hapusinfo()"/>
                  <button type="submit" class="sm z-10" style="
                                                          justify-content: center;
                                                          align-items: center;
                                                          padding: 0px 24px;
                                                          width: 120px;
                                                          height: 41px;
                                                          right: 135px;
                                                          top: 698px;
                                                          background: #D89A47;
                                                          border-radius: 0px 400px 400px 0px;
                                                          color: #FFFFFF;" 
                    >Subscribe</button>
                </p>
                <div style="color:red;">{{message}}</div>
              </form>
            </div>
        </div>

    
      </section>

      <footer-component />
  </div>


  <div class="visible sm:invisible top-0" style="margin-top:-200%;z-index:1;">

  <section>
      <div class="flex header relative items-center" style="width: 100%;/* height: 9rem; */">
        <img
          class="flex m-auto top-0 left-0 right-0 pt-16"
          :src="patternVue" style="max-width: 50%; vertical-align:top;"
          alt="..."
        />
      </div>

      <div  class="flex" style="padding-top:40px;">
        <div  class="grow sm:w-4/12" style="
                                        position: relative;
                                        width: 30%;
                                        font-size: 400%;                                    
                                    
                                        font-family: 'Poppins';
                                        font-style: normal;
                                        font-weight: 700;
                              
                                        /* identical to box height */
                                        letter-spacing: 0.01em;
                                        -webkit-text-stroke: 2px #f7ebda;
                                        color:white;
        ">
          <span style="font-size:20%;"><b style="font-size:500%;">{{ day() }}</b>d</span>
        </div>

        <div class="grow sm:w-4/12" style="
                                        position: relative;
                                        width: 30%;
                                        font-size: 400%;                                                                         
                                        text-align:center;
                                        font-family: 'Poppins';
                                        font-style: normal;
                                        font-weight: 700;
                                      
                                        /* identical to box height */
                                        letter-spacing: 0.01em;
                                        -webkit-text-stroke: 2px #f7ebda;
                                        color:white;
        ">
          <span style="font-size:20%;"><b style="font-size:500%;">{{ hour() }}</b>h</span>
        </div>

        <div class="grow sm:w-4/12" style="
                                      -webkit-text-stroke: 2px #f7ebda;
                                      color:white;
                                      position: relative;
                                      width: 30%;
                                      margin-right: 0;
                                      font-size: 400%;                                   
                                      text-align:right;
                                      font-family: 'Poppins';
                                      font-style: normal;
                                      font-weight: 700;
                          
                                      /* identical to box height */
                                      letter-spacing: 0.01em;

        ">
          <span style="font-size:20%;"><b style="font-size:500%;">{{ minute() }}</b>m</span>
        </div>
      </div>
  </section>

  <section style="">
      <div class="" 
      style=" padding-top: -4%;padding-left:8%;
    ">
        <div class="relative h-16 w-full text-xl" style="font-family:poppins; font-weight:bold; color:#D89A47; padding-top:8px; "><p>We are</p>
                                                                                                                            <p style="padding-top:8px">Coming Soon.</p>          </div>


        <div class="relative text-gray-700" style="margin-right:39px; padding-top:20px;">
        <p
        style="
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 700;
                font-size: 10px;
                line-height: 30px;
                letter-spacing: 0.01em;
                color: #000000;
                " 
        >Get notified when we launch</p>
        
  <form class="example" @submit.prevent="addProduct">
    <input type="text" placeholder="Email" v-model="product.detail" @input="hapusinfo()">
    <button type="submit">Subscribe</button>
    <div style="color:red;">{{message}}</div>
  </form>



            </div>
        </div>

    
      </section>
      <p style="padding-top:40px;"></p>
      <footer-component />

  </div>


  </div>
</template>

