<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <card-table color="dark">
        <template #body>
          <tbody style="text-align:center;">
            <tr v-for="(user, index) in userInfo" :key="user.id">
            <td>{{ index+1 }}</td>
            <td>{{ user.detail }}</td>
            <td>
              <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" @click="edit(user.id)">Edit</button>
              <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full" @click="hapususer(user.id)">Delete</button>
            </td>
          </tr>
          </tbody>
        </template>
      </card-table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import CardTable from "@/components/Cards/CardTableRight.vue";
export default {
  name: "dashboard-page",
  data: () => ({
    userInfo: [],
  }),
  components: {
    CardTable,
  },
  methods: {
    getEmail() {
      axios.get(this.$apiAdress + "/product?token=" + localStorage.getItem("api_token"))
      .then((response) => {
        this.userInfo  = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
      ;
    },
  }, 
  created(){
    this.getEmail();
  },
};
</script>
