<template>
  <div :class="{ loader: true, fadeout: !isLoading }">
    <img row wrap align="center" align-center src="images/qshare.png" alt="logo" style="
     position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    "/>
    
  </div>
</template>

<script>
export default {
  name: "LoadingScreen",
  props: ["isLoading"]
};
</script>

<style scoped>
.loader {
  background-color: #ffffff;
  min-height: 100vh;
  color: white;
  display: block;
  font-size: 32px;
  left: 0;
  overflow: hidden;
  padding-top: 10vh;
  text-align: center;
  margin: 0;
  position: absolute;
  margin-top: 20px;
  top: 50%;
  bottom: 50%;
  vertical-align: center;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

.fadeout {
  animation: fadeout 3s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>