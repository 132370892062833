import { createApp } from 'vue'
import { createWebHistory, createWebHashHistory, createRouter } from 'vue-router' 

// styles

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes :configRoutes(), // short for `routes: routes`
})

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

// layouts


import Admin from "@/layouts/TemplateUser.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout

import Dashboard from "@/views/admin/Dashboard.vue";
import Settings from "@/views/admin/Settings.vue";
import Tables from "@/views/admin/Tables.vue";
import Maps from "@/views/admin/Maps.vue";

// views for Auth layout

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";

// views without layouts

import Landing from "@/views/Landing.vue";
import Profile from "@/views/Profile.vue";
import Index from "@/Pages/Index.vue";

// routes
router.beforeEach((to, from, next) => {
  let roles = localStorage.getItem("roles");
  let token = localStorage.getItem("api_token");
  if(roles != null){
    roles = roles.split(',')
  }
  if(to.matched.some(record => record.meta.requiresAdmin)) {
    if(roles != null && roles.indexOf('admin') >= 0 && token != null){
      next()
    }else{
      next({
        path: '/auth/login',
        params: { nextUrl: to.fullPath }
      })
    }
  }else if(to.matched.some(record => record.meta.requiresUser)) {
    if(roles != null && roles.indexOf('user') >= 0 && token != null){
      next()
    }else{
      next({
        path: '/auth/login',
        params: { nextUrl: to.fullPath }
      })
    }
  }else{
    next()
  }
})
export default router

function configRoutes () {
    return [
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: Admin,
    children: [
      {
        path: "/admin/dashboard",
        component: Dashboard,
        meta:{
            requiresAdmin: true
          }
      },
      {
        path: "/admin/settings",
        component: Settings,
        meta:{
            requiresAdmin: true
          }
      },
      {
        path: "/admin/tables",
        component: Tables,
        meta:{
            requiresAdmin: true
          }
      },
      {
        path: "/admin/maps",
        component: Maps,
        meta:{
            requiresAdmin: true
          }
      },
    ],
  },
  {
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    name: "auth",
    children: [
      {
        path: "/auth/login",
        component: Login,
      },
      {
        path: "/auth/register",
        component: Register,
      },
    ],
  },
  {
    path: "/landing",
    component: Landing,
    meta:{
        requiresAdmin: true
      }
  },
  {
    path: "/profile",
    component: Profile,
    meta:{
        requiresAdmin: true
      }
  },
  {
    path: "/",
    component: Index,
    
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
]
};