import { createApp } from "vue";


import router from "./route";
import App from "./App.vue";
import axios from "axios";

const app = 
createApp(App);
app
.use(router, axios)
.mount("#app");

app.config.globalProperties.$apiAdress = "https://qshare.co.id/api";
